* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  max-width: 100%;
}

/* General Styles*/

html {
  font-size: 62.5%;
}

body {
  font-size: 1.8rem;
  line-height: 1.5;
  color: #4f4f4f;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 50%;
}

.navigation {
  background-color: #f1a384;
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.links {
  width: 10%;
  margin: 2%;
  text-align: center;
  text-decoration: none;
  color: black;
}

.links:hover {
  width: 12%;
  background-color: #7effe3;
  color: white;
}

.body-container h2 {
  text-align: center;
  padding: 3%;
}

.interior-exterior-pic {
  position: relative;
  text-align: center;
  padding: 2%;
  color: white;
}

.interior-exterior-pic img {
  width: 30%;
  height: 30%;
}

.interior-exterior-pic img:hover {
  width: 33%;
  height: 33%;
}

.pos-absolute {
  position: absolute;
  top: 50%;
  left: 43%;
  color: white;
}

.exterior-pic {
  position: relative;
  text-align: center;
  padding: 2%;
  color: white;
}

.exterior-pic img {
  width: 30%;
  height: 30%;
}

.exterior-pic img:hover {
  width: 33%;
  height: 33%;
}

.pos-abs {
  position: absolute;
  top: 50%;
  left: 48%;
  color: white;
}

.footer-container {
  background-color: #f1a384;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 4%;
}

.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #edd474;
}

.services-container h2 {
  margin-top: 4%;
}

.services {
  width: 50%;
  margin: 4%;
}

.service {
  display: flex;
  flex-direction: column;
  margin-bottom: 7%;
}

.book {
  width: 30%;
  margin-left: 35%;
  margin-top: 2%;
  text-align: center;
  background-color: #7effe3;
  color: black;
  text-decoration: none;
}

.book:hover {
  background-color: #f1a384;
}

.gallery-container {
  background-color: #7effe3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-container h2 {
  margin: 4%;
}

.gallery {
  width: 50%;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery img {
  width: 100%;
  margin: 4%;
}

@media (max-width: 950px) {
  .links {
    width: 22%;
  }
  .links:hover {
    width: 24%;
  }

  .interior-exterior-pic img {
    width: 30%;
  }

  .interior-exterior-pic img:hover {
    width: 33%;
  }

  .exterior-pic img {
    width: 30%;
  }

  .exterior-pic img:hover {
    width: 33%;
  }

  .pos-absolute {
    top: 50%;
    left: 42.5%;
  }

  .pos-abs {
    top: 50%;
    left: 47.5%;
  }
}

@media (max-width: 800px) {
  .links {
    width: 22%;
  }
  .links:hover {
    width: 24%;
  }

  .interior-exterior-pic img {
    width: 40%;
  }

  .interior-exterior-pic img:hover {
    width: 43%;
  }

  .exterior-pic img {
    width: 40%;
  }

  .exterior-pic img:hover {
    width: 43%;
  }

  .pos-absolute {
    top: 50%;
    left: 40%;
  }

  .pos-abs {
    top: 50%;
    left: 46.5%;
  }
}

@media (max-width: 700px) {
  .links {
    width: 22%;
  }
  .links:hover {
    width: 24%;
  }

  .interior-exterior-pic img {
    width: 50%;
  }

  .interior-exterior-pic img:hover {
    width: 53%;
  }

  .exterior-pic img {
    width: 50%;
  }

  .exterior-pic img:hover {
    width: 53%;
  }

  .pos-absolute {
    top: 50%;
    left: 38.3%;
  }

  .pos-abs {
    top: 50%;
    left: 46%;
  }

  .book {
    width: 40%;
    margin-left: 30%;
  }
}

@media (max-width: 560px) {
  .links {
    width: 22%;
  }
  .links:hover {
    width: 24%;
  }

  .interior-exterior-pic img {
    width: 60%;
  }

  .interior-exterior-pic img:hover {
    width: 63%;
  }

  .exterior-pic img {
    width: 60%;
  }

  .exterior-pic img:hover {
    width: 63%;
  }

  .pos-absolute {
    top: 50%;
    left: 35%;
  }

  .pos-abs {
    top: 50%;
    left: 45%;
  }

  .services {
    width: 60%;
  }
}

@media (max-width: 460px) {
  .links {
    width: 22%;
  }
  .links:hover {
    width: 24%;
  }

  .interior-exterior-pic img {
    width: 70%;
  }

  .interior-exterior-pic img:hover {
    width: 73%;
  }

  .exterior-pic img {
    width: 70%;
  }

  .exterior-pic img:hover {
    width: 73%;
  }

  .pos-absolute {
    top: 50%;
    left: 32%;
  }

  .pos-abs {
    top: 50%;
    left: 44%;
  }

  .services {
    width: 80%;
  }
}

@media (max-width: 360px) {
  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    width: 100%;
    height: 20%;
  }

  .navigation {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .links {
    width: 30%;
    height: 60%;
    text-align: center;
    text-decoration: none;
    color: black;
    margin: 1.8%;
  }

  .links:hover {
    width: 40%;
    height: 70%;
    background-color: #7effe3;
    color: white;
  }

  .interior-exterior-pic {
    position: relative;
    text-align: center;
    color: white;
  }

  .interior-exterior-pic img {
    width: 80%;
    height: 80%;
  }

  .interior-exterior-pic img:hover {
    width: 85%;
    height: 85%;
  }

  .pos-absolute {
    position: absolute;
    top: 50%;
    left: 30%;
    color: white;
  }

  .exterior-pic {
    position: relative;
    text-align: center;
    color: white;
  }

  .exterior-pic img {
    width: 80%;
    height: 80%;
  }

  .exterior-pic img:hover {
    width: 85%;
    height: 85%;
  }

  .pos-abs {
    position: absolute;
    top: 50%;
    left: 42%;
    color: white;
  }

  .services-container {
    background-color: #edd474;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .services-container h2 {
    margin-top: 10%;
  }

  .services {
    margin-top: 4%;
  }

  .service {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-bottom: 15%;
  }

  .book {
    margin-top: 10%;
    margin-left: 25%;
    width: 50%;
    background-color: #7effe3;
    text-align: center;
    color: black;
    text-decoration: none;
  }

  .book:hover {
    background-color: #f1a384;
  }

  .gallery-container {
    background-color: #7effe3;
  }

  .gallery {
    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .gallery img {
    width: 100%;
    height: 50%;
    margin-top: 3%;
  }

  .footer-container h4 {
    margin: 2%;
  }

  .footer-container p {
    margin: 2%;
  }

  .social-media {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social-media h5 {
    margin: 2%;
  }
}

@media (max-width: 310px) {
  .links {
    width: 26%;
  }
  .links:hover {
    width: 27%;
  }

  .interior-exterior-pic img {
    width: 70%;
  }

  .interior-exterior-pic img:hover {
    width: 73%;
  }

  .exterior-pic img {
    width: 70%;
  }

  .exterior-pic img:hover {
    width: 73%;
  }

  .pos-absolute {
    left: 23%;
  }

  .pos-abs {
    left: 40%;
  }

  .footer-container p {
    font-size: 1.5rem;
  }
}
